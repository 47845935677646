import React from 'react';
import pic from '../../images/aboutimage.png'
import styled from 'styled-components';
import './Specialty.css';

const Container = styled.div`
  ${tw`container h-auto pb-20 mx-auto px-4 bg-grey-lighter`}
`

const Cards = styled.div`
  ${tw`w-48 h-48 bg-indigo hover:bg-indigo-darker p-2 m-4 md:p-4 md:m-8 rounded-lg`}
`

const Header = styled.h1`
  ${tw`text-base text-grey-lighter`}
`

const ContentContainer = styled.div`
    ${tw`flex flex-row flex-wrap grid grid-flow-row`}
`

const Text = styled.p`
  ${tw`text-xs text-grey-lighter`}
`

const Content = styled.div`
    ${tw`flex flex-row`}
`

const Specialty = () => {
  return (
    <Container>
      <div class="py-4 px-2 mx-2 md:px-8 md:mx-8">
        <h2>Our range of services</h2>
        <p class="text-gray-700 font-lg"  style={{ maxWidth: '50em' }}>
          Our consultants are highly verified and offer consultations in the following 
          medical cores.
        </p>
      </div>
      <Content>
        <div className="image-container hidden md:flex">
          <img src={pic} alt="" className="image" />
        </div>
        <ContentContainer>
          <Cards className="card1">
            <Header>Medical checkups</Header>
            <Text >
            <ul class="ml-0">
            <li><i>General Consultation </i></li>
            <li><i>Gynaecology</i></li>
            <li><i>Pediatrics</i></li>
            <li><i>Obstetrics</i></li>
            <li><i>Dermatology</i></li>
            <li><i>Dental/Optical health</i></li>
          </ul>
            </Text>
          </Cards>
          <Cards className="card2">
            <Header>Mental health counselling</Header>
            <Text>
              <ul class="ml-0">
              <li><i>Therapy sessions</i></li>
              <li><i>Depression</i></li>
              <li><i>Psychology</i></li>
              <li><i>Anger Management</i></li>
              <li><i>General Counseling</i></li>
            </ul>
            </Text>
          </Cards>
          <Cards className="card3">
            <Header>Domestic counselling</Header>
            <Text>
              <ul class="ml-0">
              <li><i>Relationship counselling</i></li>
              <li><i>Family planning</i></li>
              <li><i>Domestic violence</i></li>
              <li><i>Pre-marital counsel</i></li>
            </ul>
            </Text>
          </Cards>
          <Cards className="card4">
            <Header>Life Coaching</Header>
            <Text>
              <ul class="ml-0">
              <li><i>Mentorship </i></li>
              <li><i>Career growth</i></li>
              <li><i>Guidance & Counselling</i></li>
              <li><i>Psychotherapy</i></li>
              
            </ul>
            </Text>
          </Cards>
        </ContentContainer>
      </Content>
    </Container>
  )
}

export default Specialty
