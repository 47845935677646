import React from 'react';
import pic1 from '../../images/IMG_ph5.png';
import pic2 from '../../images/IMG_ph11.png';
import pic3 from '../../images/IMG-conschat.png';
import appStore from '../../images/App Store.svg'
import playStore from '../../images/Play Store.svg'

import './DigitalClinic.css';

const DigitalClinic = () => {
  return (
    <div>
      <h1
        class="font-hairline text-center text-4xl text-indigo-800"
        style={{ margin: "50px 0" }}
      >
        How it <span class="font-bold underline">works</span>
      </h1>

      <div class="digitalclinics">
        <div class="flex justify-around items-center ">
          <div class="clinicimage">
            <img src={pic1} alt="" width={500} />
          </div>
          <div class="flex">
            <div
              class="mr-4 flex justify-center items-center bg-indigo-900"
              style={{
                // background: '#2f365f',
                height: '45px',
                width: '45px',
                color: 'white',
                borderRadius: '50%',
              }}
            >
              1
            </div>
            <div  class="cliniclist" >
              <h1 class="font-hairline text-3xl text-indigo-800" >
                Onboard with your price per session
                {/* Complete a virtual in-app consultation, right from your phone */}
              </h1>
              <p class="font-thin ">
                Register an account above, await verification process and 
                onboard fully with your own price and duration per sessions.
              </p>
            </div>
          </div>
        </div>

        <div class="flex justify-center items-center ">
          <div class="flex">
            <div
              class="mr-4 flex justify-center bg-indigo-900 items-center"
              style={{
                // background: '#2f365f',
                height: '45px',
                width: '45px',
                color: 'white',
                borderRadius: '50%',
              }}
            >
              2
            </div>
            <div class="cliniclist">
              <h1 class="font-hairline text-3xl text-indigo-800" >
                Share health articles or tips with health threads
              </h1>
              <p class="font-thin">
                Use our health thread in-app to share health related articles,
                medical tips or your personal health stories to engage your 
                patients. 
                {/* The majority of cases can be resolved during the initial, in-app
                consultation. However, if our doctors recommend in-person care,
                our Support team will be in touch to help schedule an
                appointment at a Babylon by TELUS Health clinic. */}
              </p>
            </div>
          </div>
          <div class="clinicimage">
            <img src={pic2} alt="" width={500} />
          </div>
        </div>

        <div class="flex justify-center items-center ">
          <div class="clinicimage" >
            <img src={pic3} alt="" width={500} />
          </div>
          <div class="flex ">
            <div
              class="mr-4 flex bg-indigo-900 justify-center items-center"
              style={{
                // background: '#2f365f',
                height: '45px',
                width: '45px',
                color: 'white',
                borderRadius: '50%',
              }}
            >
              3
            </div>
            <div class="cliniclist" >
              <h1 class="font-hairline text-3xl text-indigo-800" >
              Conduct virtual consultations, right from your phone
                {/* Visit a Babylon by TELUS Health clinic */}
              </h1>
              <p class="font-thin">
                Set your availability at will and conduct consultation sessions for 
                clients at your own convenience in the application. Write consultation 
                notes and prescription at the end of each session. Refer patients 
                to our verified medical labs or pharmacies for sample collections or
                medication deliveries. 
                {/* See a doctor at one of our clinics at the time of your scheduled
                in-person appointment. Following your consultation, all of your
                doctor’s notes and prescriptions will be stored on your Babylon
                by TELUS Health app. */}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class ="flex-1 flex w-full justify-evenly items-center bg-indigo-800 h-64 upsh patientdownload">
                <div class="flex flex-col patientdownloadselect">
                    <span  class="text-4xl font-thin text-white slide-in-bottom-h1"> Get started today</span>
                    <div class="flex flex-row text-center justify-center">
             
                        <a href="https://testflight.apple.com/join/4r4wzfWx">
                            <img src={appStore} class="h-8 mr-5 mb-0  hvr-push"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.healthpeer.hpconsultant">
                            <img src={playStore} class="h-8 mb-0  hvr-push" />
                        </a> 
                    </div>
                </div>
                <ol class="text-white text-xl mb-0 patientdowloadlist">
                   <li class="slide-in-bottom-subtitle">1. Download the app</li> 
                   <li class="slide-in-bottom-subtitle">2. Onboard with your details</li> 
                   <li class="slide-in-bottom-subtitle">3. Offer medical consultations</li> 
                </ol>
            </div>
    </div>
  )
}

export default DigitalClinic
