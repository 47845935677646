import React,{useState} from 'react';
import styled from 'styled-components';
import pic from '../../images/consultationjpeg.jpg';
import phonedoc from '../../images/phonedoc.png';
import Speciality from '../Specialty/Specialty';
import bgPath from '../../images/bg.svg'
import { FaLaptopMedical } from 'react-icons/fa';
import { BiTime } from "react-icons/bi";
import { GiFlowerPot } from 'react-icons/gi';
import { IoMdCash } from 'react-icons/io';

import './consultant.css'


const BG = styled.div`
  ${tw` w-full bg-right bg-cover`};
`

const Consultant = () => {

    const [first, setFirst] = useState(false);
    const [second, setSecond] = useState(false);
    const [third, setThird] = useState(false);
    const [fourth, setFourth] = useState(false);

    const handleFirst = () => setFirst(!first);
    const handleSecond = () => setSecond(!second);
    const handleThird = () => setThird(!third);
    const handleFourth = () => setFourth(!fourth);

    React.useEffect(() => {
      showRegistration()
          const footer = document.getElementsByClassName('formFooter')
              for (let i = 0; i < footer.length; i++ ) {
                footer[i].style.display = "none";
            }
    },[])

    function showRegistration(){
      var ifr = document.getElementById("JotFormIFrame-193436602902557");
            if (window.location.href && window.location.href.indexOf("?") > -1) {
                var get = window.location.href.substr(window.location.href.indexOf("?") + 1);
                if (ifr && get.length > 0) {
                    var src = ifr.src;
                    src = src.indexOf("?") > -1 ? src + "&" + get : src + "?" + get;
                    ifr.src = src;
                }
            }
            window.handleIFrameMessage = function (e) {
              let iframe;
                if (typeof e.data === 'object') { return; }
                var args = e.data.split(":");
                if (args.length > 2) { iframe = document.getElementById("JotFormIFrame-" + args[(args.length - 1)]); } else { iframe = document.getElementById("JotFormIFrame"); }
                if (!iframe) { return; }
                switch (args[0]) {
                    case "scrollIntoView":
                        iframe.scrollIntoView();
                        break;
                    case "setHeight":
                        iframe.style.height = args[1] + "px";
                        break;
                    case "collapseErrorPage":
                        if (iframe.clientHeight > window.innerHeight) {
                            iframe.style.height = window.innerHeight + "px";
                        }
                        break;
                    case "reloadPage":
                        window.location.reload();
                        break;
                    case "loadScript":
                        var src = args[1];
                        if (args.length > 3) {
                            src = args[1] + ':' + args[2];
                        }
                        var script = document.createElement('script');
                        script.src = src;
                        script.type = 'text/javascript';
                        document.body.appendChild(script);
                        break;
                    case "exitFullscreen":
                        if (window.document.exitFullscreen) window.document.exitFullscreen();
                        else if (window.document.mozCancelFullScreen) window.document.mozCancelFullScreen();
                        else if (window.document.mozCancelFullscreen) window.document.mozCancelFullScreen();
                        else if (window.document.webkitExitFullscreen) window.document.webkitExitFullscreen();
                        else if (window.document.msExitFullscreen) window.document.msExitFullscreen();
                        break;
                }
                var isJotForm = (e.origin.indexOf("jotform") > -1) ? true : false;
                if (isJotForm && "contentWindow" in iframe && "postMessage" in iframe.contentWindow) {
                    var urls = { "docurl": encodeURIComponent(document.URL), "referrer": encodeURIComponent(document.referrer) };
                    iframe.contentWindow.postMessage(JSON.stringify({ "type": "urls", "value": urls }), "*");
                }
                
            };
            if (window.addEventListener) {
                window.addEventListener("message", handleIFrameMessage, false);
            } else if (window.attachEvent) {
                window.attachEvent("onmessage", handleIFrameMessage);
            }

           
    }

    return (
      <div>
        <BG style={{backgroundImage:`url(${bgPath})`}}>
          <div class="flex ml-5 w-full justify-between consultantmain1">
            <div class="w-1/2 consultantimagemobile">
              <img src={phonedoc} alt="" style={{height:365}} />
            </div>
            <div class="w-1/2 px-2 self-center consultantmain">
            <h1 class="font-bold text-3xl md:text-5xl  text-indigo-800 text-justify">
              Earn extra income by offering medical consultancy services 
              at your own price and convenience.
              
            </h1>
            
            <a target="__blank" href="https://www.jotform.com/form/193436602902557" class="text-md p-2 bg-indigo-200 text-indigo-800 hvr-push">
              APPLY NOW
              
            </a>
            {/* <p class="font-thin">one-on-one consultations at your convenience</p> */}
          </div>
          <div class="w-1/2 consultantimage">
            <img src={phonedoc} alt="" style={{height:600}} />
          </div>
        </div>
      </BG>
        <div class="bg-indigo-800 text-white py-4 my-2 md:my-0">
          <h1
            class="text-center font-hairline py-4 text-white">
            Your realtime mobile clinic…
          </h1>
          <div class="flex justify-around consultantmobile">
            <div class="text-center consultantmobilelist">
              <div class="flex justify-center">
                <BiTime  style={{ fontSize: '70px', color: '#fff' }} />
              </div>
              <p class="font-semibold text-xl">We care about your time</p>
              <p class="font-light text-md text-justify">
                Own your schedule and offer consultations only at your convenience.
              </p>
            </div>
            <div class="text-center consultantmobilelist">
              <div class="flex justify-center">
                <FaLaptopMedical
                  style={{ fontSize: '70px', color: '#fff' }}
                />
              </div>
              <p class="font-semibold text-xl">
                Session pricing in your hands
              </p>
              <p class="font-light text-md text-justify">
                Set your prices per consultation session at your own terms.
              </p>
            </div>
            <div class="text-center consultantmobilelist">
              <div class="flex justify-center">
                <IoMdCash style={{ fontSize: '70px', color: '#fff' }} />
              </div>
              <p class="font-semibold text-xl">
                Withdraw your earnings instantly
              </p>
              <p class="font-light text-md text-justify">
                Feel free to withdraw your earnings directly to your preffered account anytime anywhere.
              </p>
            </div>
          </div>
        </div>





        {/* <div class="row justify-center bg-white z-0">
         {/* <div class="col-lg-12 justify-content-center text-center pb-4">
            <a class="text-white" href="assets/docs/HEALTHPEER CONTRACT OF SERVICE.pdf" target="_blank">
                <button mat-raised-button color="primary">
                    <mat-icon mat-prefix>file_download</mat-icon> Download Contract Of Service Agreement
                </button>
            </a>
        </div>  
        <iframe id="JotFormIFrame-193436602902557" title="HP Consultant Registration "
            onLoad={window.parent.scrollTo(0,0)} allowtransparency="true" allowFullScreen={true}
            allow={"geolocation", "microphone", "camera"} src="https://form.jotform.com/193436602902557" frameBorder={0}
            style={{minWidth: '100%', height:750,  border:"none", scrolling: "no"}}>
        </iframe>
        {showRegistration()}
    </div> */}
    {/* <div class="-mt-10 z-40">

    </div> */}
        {/* <div class ="flex-1 flex w-full justify-evenly items-center bg-indigo-800 h-64 upsh">
                <div class="flex flex-col ">
                    <span  class="text-4xl font-thin text-white slide-in-bottom-h1"> Get started today</span>
                    <div class="flex flex-row text-center justify-center">
             
                        <a href="#">
                            <img src={appStore} class="h-8 mr-5 mb-0  hvr-push"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.healthpeer.hpng">
                            <img src={playStore} class="h-8 mb-0  hvr-push" />
                        </a> 
                    </div>
                </div>
                <ol class="text-white text-xl mb-0">
                   <li class="slide-in-bottom-subtitle">1. Download the app</li> 
                   <li class="slide-in-bottom-subtitle">2. Register your account free</li> 
                   <li class="slide-in-bottom-subtitle">3. Begin medical consultations</li> 
                </ol>
            </div> */}
        
        {/* <div class="p-32">
          <h1 class="font-thin text-5xl">Your questions answered...</h1>
          <div>
            <div>
              <div onClick={handleFirst}>
                <p>Is Babylon by TELUS Health service free?</p>
              </div>
              {first ? (
                <div class="p-4">
                  <p>
                    Virtual consultations with Physicians are available for all
                    residents of British Columbia, Alberta, and Ontario, in
                    addition to employees covered under BusinessPlus. Residents
                    in these provinces who are covered under MSP, AHCIP, or
                    OHIP, can access virtual consultations at no direct cost
                    (you will not be billed after your consultation).
                    Alternatively, patients without provincial health insurance
                    in these provinces can access virtual consultations for $65
                    per physician consultation. Data charges may apply for
                    service outside Wi-Fi coverage.
                  </p>
                </div>
              ) : (
                ''
              )}
              <hr />
            </div>
            <div>
              <div onClick={handleSecond}>
                <p>Why are we called Babylon by TELUS Health?</p>
              </div>
              {second ? (
                <div class="p-4">
                  <p>
                    Babylon Health is the name of the UK-based company we
                    partnered with. They chose it based on the historical
                    advancements Babylonians made in healthcare. In the ancient
                    city of Babylon, citizens needing medical advice gathered in
                    the town square to share thoughts on treatments and
                    illnesses and this is one of the earliest examples of
                    democratising healthcare.
                  </p>
                </div>
              ) : (
                ''
              )}
              <hr />
            </div>
            <div>
              <div onClick={handleThird}>
                <p>
                  How do prescriptions, diagnostic tests, and referrals work?
                </p>
              </div>
              {third ? (
                <div class="p-4">
                  <ul>
                    <li>
                      Prescriptions: After your Babylon by TELUS Health doctor
                      generates your prescription, it is handled by our Clinical
                      Support team. Your prescription will be sent to a pharmacy
                      of your choice, where it should be received within one
                      hour. You will need to provide proof of identification at
                      the pharmacy to be able to collect your prescription.
                    </li>
                    <li>
                      Diagnostic Tests: After your consultation, our Clinical
                      Support team will ask you for your preferred lab/imaging
                      center or will recommend one nearby. Our Clinical Support
                      team will then provide guidance on next steps.
                    </li>
                    <li>
                      Referrals: After your consultation, a referral letter will
                      be sent to a specialist. Once your referral is accepted, a
                      member of our Clinical Support team or the specialist
                      office will call you to schedule an appointment.
                    </li>
                  </ul>
                </div>
              ) : (
                ''
              )}
              <hr />
            </div>
            <div>
              <div onClick={handleFourth}>
                <p>Who is Babylon by TELUS Health for?</p>
              </div>
              {fourth ? (
                <div class="p-4">
                  <p>
                    Babylon by TELUS Health aims to serve Canadians currently
                    without a family doctor or those who seek medical care
                    after-hours. If you have a family doctor, we recommend you
                    continue seeing them.
                  </p>
                </div>
              ) : (
                ''
              )}
              <hr />
            </div>
          </div>
        </div> */}
      </div>
    )
}

export default Consultant;