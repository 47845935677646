import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Consultant from '../components/Consultant/Consultant';
import Speciality from "../components/Specialty/Specialty"
import DigitalClinic from '../components/DigitalClinics/DigitalClinics';

const ConsultantPage = () => (
  <Layout>
    <Consultant />
    <DigitalClinic />
    {/* <Speciality /> */}
   
  </Layout>
)

export default ConsultantPage;
